import * as React from "react"
import { CookiesProvider } from "react-cookie";

import Layout from "../components/layout"
import Seo from "../components/seo"
import Main from "../components/main";
import CookieConsent from '../components/cookieConsent';

const NotFoundPage = () => (
  <CookiesProvider>
    <Layout>
      <Seo title="404: Not found" />
        <Main
            headline="404 - Seite nicht gefunden"
            description="<a href='/'>Hier geht zurück zur Homepage</a>"
        />
      <CookieConsent />
    </Layout>
  </CookiesProvider>
)

export default NotFoundPage
